body{
  /* height: 100vh; */
  width: 100%;
  background-color: var(--background);
  /* scroll-behavior: smooth; */
  position: absolute;
    /* background-image: url("./Assets/Images/pattern.png"); */
    background-color: var(--background);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    scroll-behavior: smooth;
    overflow-x: hidden;
}
button{
  outline: none;
}
button:hover{
  outline: none;
}
html{
  scroll-behavior: smooth !important;
}
.App {
  text-align: center;
  /* height: 100%; */
  /* position: relative; */
}

#root{
  /* height: 100%; */
  overflow: hidden;
  width: 100%;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
:root{
  --font: #E3E3E3;
  --background: #060606;
  --subtext: #EAE8DD;
  --para: #FFFFFF;
  --primary: #FF0000;
  --button: #8B5CF6;
  --secondary: #DCB410;
  -webkit-text-stroke: inherit;
  -webkit-text-stroke: initial;
  -webkit-text-stroke: revert;
  -webkit-text-stroke: revert-layer;
  -webkit-text-stroke: unset;
}
@font-face {
  font-family: haverbooke;
  /* src: url(./Assets/Fonts/Haverbrooke.ttf); */
  src: url(./Assets/Fonts/Haverbrooke.ttf);
}
@font-face {
  font-family: arial;
  src: url(./Assets/Fonts/ARLRDBD.ttf);
}

@font-face {
  font-family: one-plus-light;
  src: url(./Assets/Fonts/SlateForOnePlus-Light.ttf);
}
@font-face {
  font-family: one-plus-regular;
  src: url(./Assets/Fonts/SlateForOnePlus-Regular.ttf);
}
@font-face {
  font-family: one-plus-black;
  src: url(./Assets/Fonts/SlateForOnePlus-Black.ttf);
}
@font-face {
  font-family: clash-display;
  src: url(./Assets/Fonts/ClashDisplay-Bold.otf);
}
@font-face {
  font-family: clash-display-regular;
  src: url(./Assets/Fonts/ClashDisplay-Regular.otf);
}
@font-face {
  font-family: source-code;
  src: url(./Assets/Fonts/SourceCodePro-Light.ttf);
}