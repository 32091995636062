.event{
    flex-direction: column;
    width: 90%;
}
.event-btn{
    width: fit-content;
    background-color: rgba(255, 52, 43, 0.2);
    color: #FF342B;
    border-radius: 15px;
    border: none;
    cursor: pointer;
}
.event-img{
    cursor: pointer;
}
.event-img:hover{
    transform: scale(1.03);
    transition: 1s;
}
.event-para{
    line-height: 1.6em;
    opacity: 0.6;
    word-spacing: 0.1em;
    text-align: left;
    font-family: source-code;
}
.event-heading{
    font-family: clash-display;
}
@media screen and (min-width: 768px) {
    .event{
        flex-direction: row;
    }
    .event-para{
        text-align: left;
        width: 700px;
    }
}