.footer{
    width: 100%;
    background-color: #000;
    z-index: 3;
}
.footer-link{
    font-size: medium;
}
.footer-heading{
    color: #BBBBBB;
    font-weight: 800;
    font-family: clash-display;
    font-size: 1.8em;
    word-spacing: .2em;
}
.footer-div1{
    flex-direction: column;
}
.footer-date{
    text-align: left;
    /* letter-spacing: 1em; */
    font-size: x-large;
    width: fit-content; 
}
.footer-nav{
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    row-gap: .3em;
    column-gap: 1.2em;

} 
.footer-hr{
    border: 1px solid var(--font);
    opacity: .4;

}
.footer-nav>a{
    font-size: large;
    color: #555555;
    font-weight: 600;
}
.logos{
    width: 22px;
}
.footer-link:hover{
    border: none;
    outline: none;
    text-decoration: none;
}
@media screen and (min-width: 768px) {
    .footer-div1{
        flex-direction: row;
    }  
    .logos{
        width: 23px; 
    }
    .footer-nav>a{
        font-size: large;
        color: #555555;
        font-weight: 600;
    } 
    .footer-nav{
        flex-wrap: wrap;
        width: 30%;
        justify-content: start;
        row-gap: .3em;
        column-gap: 1.2em;
    }
    .footer-container{
        width: 95%;
    }
}