#navbar{
    position: absolute;
    top: 0px;
    width: 100%;
}
#navbarTogglerDemo01{
    background-color: var(--background);
    z-index: 2;
    height: 100vh;
    /* position: fixed; */

    display: flex;

}
.navbar-nav{
    position: absolute;
    top: 23%;
    /* right: 32%; */
    /* margin-inline: auto; */
    width: 100%;

}
#navlink{
    text-decoration: none;
    color: var(--font);
    font-weight: 800;
    font-family: one-plus-black;
}
#navlink:hover{
    color: var(--primary);
}
#active{
    color: var(--primary);text-decoration: none;
    /* color: var(--font); */
    font-weight: 800;
    font-family: one-plus-black;
}
@media screen and (min-width: 576px) {
    #navbarTogglerDemo01{
        background-color: var(--background);
        z-index: 2;
        height: 100vh;
        width: 100vw;
    }
    
}
@media screen and (min-width: 768px) {
    #navbarTogglerDemo01{
        background-color: var(--background);
        height: auto;
        /* width: 100vw; */
        z-index: 2;
    }
    #navbar{
        width: 70%;
    }
    .navbar-nav{
        background-color: #000;
        position: unset;
    }
}
@media screen and (min-width: 992px) {
    #navbarTogglerDemo01{
        height: auto;
    }
    .navbar-nav{
        position: unset;
        
    }

}