.about-para{
    font-size: 20px;
    /* font-weight: lighter; */
    color: #EAE8DD;
    font-family: source-code;
    width: 90%;
    line-height: 1.6em;
    word-spacing: 1.1;
}
.about{
    background-color: #000;
    width: 100%;
    position: relative;
    /* height: 100%; */
    
}
.hidden-left{
    opacity: 0;
    transition: all 800ms;
    transform: translateX(-100%);
}
li::marker{
    content: '';
}
.show-left{
    opacity: 1;
    /* filter: blur(0); */
    transform: translateX(0);
}
.hidden-right{
    opacity: 0;
    transition: all 800ms;
    transform: translateX(+100%);
}
.show-right{
    opacity: 1;
    /* filter: blur(0); */
    transform: translateX(0);
}
.about-head{
    font-family: clash-display;
}
.image-gallery{
    display: flex;
  flex-wrap: wrap;
  cursor: pointer;
}
.image-gallery>li>img:hover{
    transform: scale(1.1);
    transition: 1s;
}
.image-gallery>li {
    height: 20vh;
    width: auto;
    flex-grow: 1;
}

.image-gallery>li>  img {
      border-radius: 10px;
    max-height: 100%;
    min-width: 100%;
    object-fit: cover;
    vertical-align: bottom;
  }

@media screen and (min-width: 768px) {
    .image-gallery>li {
        height: 40vh;
        flex-grow: 1;
    }
    .about-para{
        width: 80%;
        font-size: 24px;
    }
}