.event-img{
    width: 100%;
}
.single-img{
    width: 90%;
}
.single-btn{
    width: 100%;
    background-color: rgba(255, 52, 43, 0.2);
    color: #FF342B;
    border-radius: 15px;
    border: none;
    font-size: large;
    cursor: pointer;
}
.rule{
    width: fit-content;
    background-color: rgba(255, 52, 43, 0.2);
    color: #FF342B;
    border-radius: 15px;
    border: none;
    font-size: large;
    cursor: pointer;
}
.sevent-div{
    width: 100%;
    overflow: hidden;
    position: relative;
}
.token-para{
    color: #000;
    max-width: 70%;
}
.event-para{
    line-height: 1.8em;
}
.token{
    background-image: url('../Assets/Images/token.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 95%;
}
.token-div{
    flex-direction: column;
}
.slash{
    color: #FF342B;
    font-weight: 600;
}
.register{
    font-size: 2.2em ;
    color: #FF342B;
    font-family: clash-display-regular;
}
.prize-img{
    width: 40px;
}
.register-img{
    width: 150px;
    animation: pulse 2s infinite;
}
.sub-img{
    width: 100px;
    height: auto; 
}
.sub-img>img{
    width: 100%;
}
@keyframes pulse {
    0%{
        opacity: 0.7;
        transform: scale(1.01);
    }
    50%{
        opacity: 0.9;
        transform: scale(1.03);
    }
    100%{
        opacity: .7;
        transform: scale(1.01);
    }
}
.chead-line, .phead-line, .ghead-line, .shead-line{
    display: none;
}

.guideline{
    font-family: source-code;
}
@media screen and (min-width: 576px){
    .sub-img{
        width: 150px;
    }
}
@media screen and (min-width: 768px) {
    .single-img{
        width: 700px;
    }
    .sub-img{
        width: 150px;
    }
    .register{
        font-size: 2.5em;
    }
    .token-div{
        flex-direction: row;
    }
    .token{
        width: 60%;
    }
    .shead-line{
        /* position: absolute; */
        border: 1px solid var(--font);
        top: 0%;
        left: 25%;
        opacity: 0.4;
        display: block;
        width: 100vw;
    }
    .phead-line{
        /* position: absolute; */
        border: 1px solid var(--font);
        top: 22%;
        display: block;
        right: 14%;
        opacity: 0.4;
        width: 100vw;
    }
    .ghead-line{
        /* position: absolute; */
        border: 1px solid var(--font);
        top: 0%;
        left: 26%;
        opacity: 0.4;
        display: block;
        width: 100vw;
    }
    .chead-line{
        /* position: absolute; */
        border: 1px solid var(--font);
        top: 22%;
        display: block;
        right: 23%;
        opacity: 0.4;
        width: 100vw;
    }
    .sevent-div{
        width: 100%;
    }
}