.coord{
    opacity: 0.6;
}
.member{
  width: 100%;
}
.member-img{
    width: 100%;
    background-color: #010101;
    border-radius: 10px;
    max-height: 100%;
    height: 22vh;
    cursor: pointer;
    width: auto;
    object-fit: cover;
    vertical-align: bottom;
}
.member-img:hover{
    transform: scale(1.03);
    transition: 1s;
}
.event-head{
  word-spacing: 0.1em;
}
.skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
  }
  
  @keyframes skeleton-loading {
    0% {
      background-color: rgba(56, 56, 56,.6);
    }
    100% {
      background-color: rgba(56, 56, 56,.8);
    }
  }
.main-div{
    transition-delay: 200ms;
}
.sub-div{
    transition-delay: 400ms;
}
@media screen and (min-width: 768px) {
    .member-img{
        height: 40vh;
    }
    .member{
      width: 70%;
    }
}