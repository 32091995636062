.diagonal-div{
    display: block;
    height: auto;
    width: 100vw;
    position: relative;
    background-color: #000;
}
.explore{
    width: 150px;
  position: absolute;
  bottom: 8%;
  right: 0;
}
marquee{
  color: #FF342B;
}
.register-marquee{
    position: absolute;
    top: 50%;
}
.diagonal{
    position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background:linear-gradient(0deg, rgba(0,0,0,0) 50%, #000 50% );

}
@media screen and (min-width: 768px) {
  .explore{
    width: fit-content;
  }
}