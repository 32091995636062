.sponsorship{
    width: 100%;
    overflow-x: hidden;
    scroll-behavior: smooth;
    display: flex;
    cursor: pointer;
    white-space: nowrap;
}
.slides{
    /* width: 100%; */
    /* overflow: visible;
    transition: transform 1.5s; */
    animation: sliding 25s infinite linear;
    /* display: inline-block; */
}
.sponsor-div{
    width: 100%;
    overflow-x: scroll;
}

.sponsorship:hover .slides{
    animation-play-state: paused ;
}
.sponsor-div::-webkit-scrollbar {
    display: none;
  }
  
  .sponsor-div {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
.slides>img{
    width: 150px;
    height: auto;
}
.event-head{
    display: block;
}
@keyframes sliding {
    from{
        transform: translateX(0);
    }
    to{
        transform: translateX(-100%);
    }
}
@media screen and (min-width: 57px) {
    .slides>img{
        width: 150px;
        height: auto;
    }
}
@media screen and (min-width: 768px) {
    .sponsor-div{
        width: 80%;
    }   
    .slides>img{
        width: 150px;
        height: auto;
    }
}
