.home{
    width: 100%;
    position: relative;
}
.date-text{
    font-size: small;
}
.homeMain{
    min-height: 100vh;
}
.hidden{
    opacity: 0;
    transition: all 1s;
    transform: translateY(+300px);
    /* filter: blur(5px); */
}
.show{
    opacity: 1;
    /* filter: blur(0); */
    transform: translateY(0);
}
.home>.dates{
    font-size: 45px;
    font-weight: 800;
}
.rectangle-top{
    display: none;
}
.line-div{
    position: relative;
    display: none;
}
.balloon{
    /* Group */
        display: none;
        position: absolute;
        /* left: 93.33%; */
        /* left: 170px; */
        right: -23%;
        top: 16.14%;
        bottom: 77.86%; 
        width: 60px;
        

}
.home-div{
    width: 95%;
    flex-direction: column;
}
.line{
    border: 1px solid;
    color: #fff;
    visibility: hidden;
}
.dates{
    background-color: var(--primary);
    width: fit-content;
    color: var(--background);
    font-weight: 800;
    transition-delay: 400ms;
}
.hero-count{
    font-family: 'Roboto', sans-serif;
    font-size: 2em;
    font-weight: 600;
    animation: count 2s infinite;
    /* transition-delay: 400ms; */
}
@keyframes count {
    0%{
        opacity: 0.7;
        transform: scale(1.01);
    }
    50%{
        opacity: 0.9;
        transform: scale(1.03);
    }
    100%{
        opacity: .7;
        transform: scale(1.01);
    }
}
.hero-text{
    font-size: 2.3em;
    font-weight: 800;
    line-height: 1.5em;
    word-spacing: 1.1;
    font-family: one-plus-black;
    transition-delay: 200ms;
    color: #e3e3e3;
}

@media screen and (min-width: 768px) {
    .rectangle-top{
        position: absolute;
        display: block;
        top: 0px;
        right: 0px;
        width: 70px;
    }
    .line{
        border: 1px solid;
        color: #fff;
    }
    .balloon{
        /* Group */
            display: block;
            position: absolute;
            top: -50px;
            right: -22%;
            /* bottom: 77.86%; */
            width: 100px;

    }
    .line-div{
        display: block;
        opacity: .44;
    }
    .home-div, .line-div{
        width: 70%;
    }
    .home-div{
        flex-direction: column;
    }
    .hero-text{
        font-size: 2.6em;
    }
    .hero-count{
        font-size: 2.5em;
    }
    .line{
        visibility: hidden;
    }
}
@media screen and (min-width:992px){
    .home-div{
        flex-direction: row;
    }
    .line{
        visibility: visible;
    }
    .hero-count, .hero-text{
        font-size: 3em;
    }
}