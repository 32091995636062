.scroll{
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 2;
}
.scroll > button{
    border: none;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: var(--background);
    border: 2px solid var(--primary);
    color: var(--font);
    font-size: larger;
    cursor: pointer;
}
.scroll>button:hover{
    box-shadow: 0 0 2px var(--primary),
                0 0 5px var(--primary),
                0 0 11px var(--primary);
}